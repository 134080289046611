@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@400;500;600&family=Noto+Sans+Bengali:wght@200;300;400;500;600&family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,300;1,400;1,500&family=Tiro+Bangla&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #F4F7FF;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  height: 100vh;
  width: 100vw;

}

.textColor {
  color: #72b626;
}

.bgColor {
  background-color: #72b626;
}

.customButton {
  background-color: white;
  border: 1px solid #72b626;

  border-radius: 50em;
  width: 160px;
  height: 50px;
}

/* button:hover {
  background-color: #72b626;
  color: white;
  transition: 0.4s;

} */

tr {
  margin: 20px;

}

td {
  margin-right: 15px;
}

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

button.btn {
  width: 12rem;
  height: auto;

}

button {
  width: 3rem;
  height: 3rem;
  border: 1.5px solid #72b626;
  border-radius: 1.625rem;
  margin: 0;
}

button.btn .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #72b626;
  border-radius: 1.625rem;
}

button.btn .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

button.btn .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;

}

button.btn .circle .icon.arrow::before {
  position: absolute;
  content: '';
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

button.btn .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #282936;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;

}

button:hover .circle {
  width: 100%;
}

button:hover .circle .icon.arrow {
  background: #fff;
  -webkit-transform: translate(1rem, 0);
  transform: translate(1rem, 0);
}

button:hover .button-text {
  color: #fff;
}

.bgphoto {
  background-image: "./asset/Blogs/How to become a ethical hacker.jpg";
}